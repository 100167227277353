import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["predicateSelect"];

  connect() {
    this.fieldTypes = JSON.parse(document.getElementById("fieldTypes").value);
    this.predicateOptions = {
      "eq": "equals",
      "not_eq": "not equal to",
      "matches": "matches",
      "does_not_match": "doesn't match",
      "lt": "less than",
      "lteq": "less than or equal to",
      "gt": "greater than",
      "gteq": "greater than or equal to",
      "in": "in",
      "not_in": "not in",
      "cont": "contains",
      "not_cont": "doesn't contain",
      "i_cont": "i_cont",
      "not_i_cont": "not_i_cont",
      "start": "starts with",
      "not_start": "doesn't start with",
      "end": "ends with",
      "not_end": "doesn't end with",
      "true": "is true",
      "not_true": "not_true",
      "false": "is false",
      "not_false": "not_false",
      "present": "is present",
      "blank": "is blank",
      "null": "is null",
      "not_null": "is not null"
    };
    this.allowedPredicates = {
      "string": ["cont", "not_cont", "eq", "not_eq", "start", "not_start", "end", "not_end"],
      "text": ["cont", "not_cont", "eq", "not_eq", "start", "not_start", "end", "not_end"],
      "integer": ["eq", "not_eq", "lt", "lteq", "gt", "gteq"],
      "date": ["eq", "not_eq", "lt", "lteq", "gt", "gteq"],
      "datetime": ["eq", "not_eq", "lt", "lteq", "gt", "gteq"],
      "boolean": ["true", "false"],
      "uuid": ["eq", "not_eq", "start", "not_start", "end", "not_end"]
    };

    const attributeFields = document.querySelectorAll("[id^='q_'][id$='_name']");
    attributeFields.forEach((field) => {
      const event = { target: field }; // Simulate a change event
      this.updatePredicates(event);
    });
  }

  updatePredicates(event) {
    const selectedField = event.target.value;
    const selectedFieldId = event.target.id;
    const selectedFieldCollectionId = selectedFieldId.split("q_").pop().split("_a")[0];
    const dataType = this.fieldTypes[selectedField];

    const predicateField = document.getElementById(`q_${selectedFieldCollectionId}_p`);
    const valueField = document.getElementById(`q_${selectedFieldCollectionId}_v_0_value`);

    const predicateTypes = this.allowedPredicates[dataType];
    if (!predicateTypes) return;

    for (var i = predicateField.options.length - 1; i >= 0; i--) {
      predicateField.remove(i);
    };
    predicateTypes.forEach(t => {
      const predicate = this.predicateOptions[t];
      if (predicate) {
        const opt = document.createElement("option");
        opt.text = predicate;
        opt.value = t;
        predicateField.appendChild(opt);
      }
    });

    valueField.type = "text";
    if (dataType === "integer") valueField.type = "number";
    if (dataType === "date") valueField.type = "date";
    if (dataType === "datetime") valueField.type = "date";
  }
}
